export interface iMutagenicity {
    "INCHIKEY": string;
    "MODEL_NAME": string;
    "MODEL_VERSION": string;
    "PROGRAM_VERSION": string;
    "REPORT_DATE": string;
    "PREDICTION_AUTHOR": string;
    "PREDICTION_DATE": string;
    "MODEL_ENDPOINT": string;
    "KNOWLEDGE_BASES": string;
    "KNOWLEDGE_BASE_MODIFIED_DATE": string;
    "KNOWLEDGE_BASE_CERTIFIED_BY": string;
    "SPECIES": string;

    "ALERT_COUNT": string;
    "RESULT_MUTAGENICITY": string;
    "REASONING_OVERVIEW": string;
    "REASONING_DESCRIPTION": string;

    "CONFIDENCE": string;

    "EQUIVOCAL_LEVEL": string;
    "REASONING_TYPE": string;
    "SENSITIVITY_LEVEL": string;

    "POSITIVE_HYPOTHESIS_COUNT": string;
    "NEGATIVE_HYPOTHESIS_COUNT": string;
    "POSITIVE_HYPOTHESIS_COUNT_OVERRULED": string;
    "NEGATIVE_HYPOTHESIS_COUNT_OVERRULED": string;
    "TOTAL_HYPOTHESIS_COUNT": string;


}

export const Mutagenicity: iMutagenicity = {
    CONFIDENCE: "",
    EQUIVOCAL_LEVEL: "",
    INCHIKEY: "",
    SPECIES: "",
    MODEL_ENDPOINT: "",
    MODEL_NAME: "",
    MODEL_VERSION: "",
    KNOWLEDGE_BASES: "",
    KNOWLEDGE_BASE_MODIFIED_DATE: "",
    KNOWLEDGE_BASE_CERTIFIED_BY: "",
    ALERT_COUNT: "",
    RESULT_MUTAGENICITY: "",
    REASONING_OVERVIEW: "",
    REASONING_DESCRIPTION: "",

    NEGATIVE_HYPOTHESIS_COUNT: "",
    NEGATIVE_HYPOTHESIS_COUNT_OVERRULED: "",
    POSITIVE_HYPOTHESIS_COUNT: "",
    POSITIVE_HYPOTHESIS_COUNT_OVERRULED: "",
    PREDICTION_DATE: "",
    PROGRAM_VERSION: "",
    REASONING_TYPE: "",
    REPORT_DATE: "",
    SENSITIVITY_LEVEL: "",
    TOTAL_HYPOTHESIS_COUNT: "",
    PREDICTION_AUTHOR: ""

}
