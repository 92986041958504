//
// Generic Event listener system
//

import {EventsType} from "./events-types";
import {isUndefined} from "./utils/string";
import {
    AROMA,
    CANNABIS_COMPOUNDS,
    COMPOUND,
    MARKET_CBD, MUTAGENICITY, PHARMACO_DYNAMICS,
    PHYSICAL_CHARACTERIZATION_CBD,
    REFERENCES, STABILITY,
} from "./api/Compounds";
import {CBD_PK_INDICES, STUDIES_CBD, STUDIES_METABOLITES} from "./api/Studies";
import {STRAINS, STRAINS_COMP_CONC} from "./api/Strains";
import {
    PRODUCT_ANALYSIS_VALUES,
    PRODUCT_DEGRADATION, PRODUCT_DEGRADATION_COMPOUNDS,
    PRODUCTS_ANALYSIS_METHODS,
    PRODUCTS_ANALYSIS_REPORTS
} from "./api/Products";

class Events {
    private eventListeners: Map<EventsType, Map<string, ((event: any) => void)>> = new Map();

    on( eventType: EventsType,
        listener: ((event: any) => void),
        UUID: string = 'undefined' )
    {
        if (!this.eventListeners.has(eventType)) {
            this.eventListeners.set(eventType, new Map());
            document.addEventListener(eventType, this.handleEvent);
        }

        this.eventListeners.get(eventType)?.set(UUID, listener);
    }

    off( eventType: EventsType,
         UUID: string = 'undefined')
    {
        const listeners = this.eventListeners.get(eventType);
        if (listeners && listeners.has(UUID)) {
            listeners.delete(UUID);
            if (listeners.size === 0) {
                document.removeEventListener(eventType, this.handleEvent);
                this.eventListeners.delete(eventType);
            }
        }
    }

    trigger( eventType: EventsType,
             data: any)
    {
        const event = new CustomEvent(eventType, { detail: data });
        document.dispatchEvent(event);
    }

    triggerByID (id: string){

        if (isUndefined(id)) return;

        const table = id.split("/")[0];
        let event_type = EventsType.RELOAD;

        switch (table) {
            case AROMA:
                event_type = EventsType.SEARCH_BY_AROMA;
                break;
            case COMPOUND:
                event_type = EventsType.SEARCH_BY_COMPOUND;
                break;
            case CANNABIS_COMPOUNDS:
                event_type = EventsType.SEARCH_BY_CANNABIS_COMPOUND;
                break;
            case MARKET_CBD:
                event_type = EventsType.SEARCH_BY_CBD_MARKET_DRUG;
                break;
            case STUDIES_METABOLITES:
                event_type = EventsType.SEARCH_BY_METABOLITE;
                break;
            case REFERENCES:
                event_type = EventsType.SEARCH_BY_REFERENCE;
                break;
            case STRAINS:
                event_type = EventsType.SEARCH_BY_STRAIN;
                break;
            case STUDIES_CBD:
                event_type = EventsType.SEARCH_BY_STUDY;
                break;
            case STRAINS_COMP_CONC:
                event_type = EventsType.SEARCH_STRAIN_BY_CONCENTRATION;
                break;
            case CBD_PK_INDICES:
                event_type = EventsType.SEARCH_BY_STUDY_PK_INDICES;
                break;
            case PHYSICAL_CHARACTERIZATION_CBD:
                event_type = EventsType.SEARCH_BY_PHYSICAL_PROPERTIES_CBD;
                break;
            case MUTAGENICITY:
                event_type = EventsType.SEARCH_BY_MUTAGENICITY;
                break;
            case PHARMACO_DYNAMICS:
                event_type = EventsType.SEARCH_BY_PHARMACO_DYNAMICS;
                break;
            case PRODUCTS_ANALYSIS_REPORTS:
                event_type = EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT;
                break;
            case PRODUCT_ANALYSIS_VALUES:
                event_type = EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE;
                break;
            case PRODUCTS_ANALYSIS_METHODS:
                event_type = EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD;
                break;
            case PRODUCT_DEGRADATION:
                event_type = EventsType.SEARCH_BY_PRODUCT_DEGRADATION;
                break;
            case PRODUCT_DEGRADATION_COMPOUNDS:
                event_type = EventsType.SEARCH_BY_PRODUCT_DEGRADATION_COMPOUND;
                break;
            case STABILITY:
                event_type = EventsType.SEARCH_BY_STABILITY;
                break;
        }

        this.trigger(event_type, {_id: id});
    }

    createObject(data: any) {
        if (isUndefined(data) || isUndefined(data._id)) return;
        this.trigger(EventsType.CREATE, data);
    }

    private handleEvent = (event: Event) => {
        const eventType = event.type as EventsType;
        const listeners = this.eventListeners.get(eventType);
        if (listeners) {
            listeners.forEach((listener) => {
                listener(event);
            });
        }
    };
}

const events = new Events();
export default events;





