import React, {useEffect} from 'react';

import {Box, IconButton, Typography} from "@mui/material";
import {FileDownload as FileDownloadIcon, Visibility as VisibilityIcon} from "@mui/icons-material";

import MaterialReactTable from 'material-react-table';

import Events from '../../../events';
import {EventsType} from '../../../events-types';

import {
    getByFilters,
    getObjectsByRegEx,
    REFERENCES,
} from '../../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";

import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";

import {Reference} from "./types";

const _ReferenceTableCard: React.FC<iBaseInnerCardProps> = ({ data ,
                                                                   setColumnFilters, columnFilters,
                                                                   // setTextSearch, textSearch,
                                                                   isActive,
                                                                   searchListBy, searchListByFilters
                                                               }) => {

    const UUID = '_ReferenceTableCard'

    const columnNames = Object.keys(Reference);
    const columnsToView = ['REFERENCE_YEAR','REFERENCE_JOURNAL','REFERENCE_AUTHORS',
        'REFERENCE_TITLE' ];

    const eventMapping: CellEventMapping = {
        'INCHIKEY': EventsType.SEARCH_BY_INCHIKEY
    };

    const columns = getColumns(Reference, 0, createCellsWithEvents(eventMapping));
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);


    // SEARCH -------------

    function _search(text: string) {
        if (text.length > 0){
            searchListBy?.(getObjectsByRegEx, REFERENCES,
                'REFERENCE_JOURNAL,REFERENCE_AUTHORS,REFERENCE_TITLE', text);
        }
    }

    const searchEvent = async(event: { detail: string }) => {
        if (isActive){
            _search(event.detail);
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === REFERENCES){
            searchListByFilters?.(getByFilters, REFERENCES, event.detail.filters, 'wizard result');
        }
    }


    // EVENTS ---------------

    useEffect(() => {

        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);


    // RENDER ----------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (

        <MaterialReactTable
            columns={columns}
            data={data?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'REFERENCE_YEAR', 'REFERENCE_TITLE', 'REFERENCE_AUTHORS',
                    'REFERENCE_JOURNAL','REFERENCE_TITLE_JOURNAL',
                    'REFERENCE_SENTENCE',
                ],
            }}


            // renderDetailPanel={({ row }) => (
            //     <Box
            //         sx={{
            //             display: 'grid',
            //             margin: 'auto',
            //             gridTemplateColumns: '1fr 1fr',
            //             width: '100%',
            //         }}
            //     >
            //         <Typography><b>Description:</b> {row.original.DESCRIPTION}</Typography>
            //     </Box>
            // )}
            //

            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            /* ROW ACTION   */

            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_REFERENCE, row.original);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>

                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "References")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "References")}

                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>

                </Box>
            )}
        />
    );
};

const ReferenceTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_ReferenceTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ReferenceTableCard);
