import React, {useEffect, useState} from 'react';
import '../css/Search.css';
import SearchStyle from '@mui/icons-material/Search'

import Events from '../events';
import { EventsType } from '../events-types';
import {getTaxonomy} from "../api/Pubchem";
import {isNotEmpty} from "../utils/string";
import {getStrainsTaxonomy} from "../api/Strains";
import {Clear} from "@mui/icons-material";
import {IconButton} from "@mui/material";


const Search = () => {

    const [payload , setPayload] = useState('')
    const [searching , setSearching] = useState(false);
    const [taxonomyResults, setTaxonomyResults] = useState<{
        compound: string[];
        gene: string[];
        taxonomy: string[];
        strains: string[];
    }>({
        compound: [],
        gene: [],
        taxonomy: [],
        strains: []
    });


    const payloadHandler = (e: any) => {
        setPayload( e.target.value );
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        submitButton();
    };

    const submitButton = () =>{

        setSearching(true);
        closeResults();

        if (isNotEmpty(payload)) {
            Events.trigger(EventsType.CLEAR_ALL_DATA, {});
            Events.trigger(EventsType.SEARCH, payload);
        }

    }
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        clearTimeout(timeoutId);
        if (!searching){
            timeoutId = setTimeout(async () => {
                const taxonomies = await getTaxonomy(searchText);
                const strains = await getStrainsTaxonomy('VARIETY_NAME', searchText);
                setTaxonomyResults(() => ({
                    ...taxonomies,
                    strains: strains.slice(0, 10),
                }));

                if (searching) closeResults();
            }, 250); // Delay of 250ms before calling getTaxonomy
        }

    };

    let timeoutId: NodeJS.Timeout;

    useEffect(() => {
        setTaxonomyResults({
            compound: [],
            gene: [],
            taxonomy: [],
            strains: []
        }); // Clear taxonomy results when search text changes
    }, [payload]);

    const handleResultClick = (result: string) => {
        setPayload(result);
        submitButton();
    };

    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeResults();
            } else if (event.key === 'Enter') {
                closeResults();
                submitButton();
            }
            else setSearching(false);
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [submitButton]);


    const closeResults = () => {
        setTaxonomyResults({
            compound: [],
            gene: [],
            taxonomy: [],
            strains: []
        });
    };

    const clearSearch =  () => {
        setPayload('')
    }

    return (
        <div className="SearchStyle">
            <form onSubmit={handleSubmit}>
                <input type="text"
                       className="search-bar"
                       onChange={payloadHandler}
                       onInput={handleTextChange} // Use onInput to capture text input changes
                       name="search_text"
                       placeholder="type here NAME, CAS, INCHIKEY, etc."
                       value={payload}
                />
                <SearchStyle className="search-button" onClick={submitButton}/>
                <IconButton onClick={clearSearch} color="primary"  >
                    <Clear  />
                </IconButton>
            </form>
            <div className="taxonomy-results-wrapper">
                {isNotEmpty(taxonomyResults.compound) && (
                    <div className="column">
                        <h3>Compound</h3>
                        <ul className="taxonomy-results">
                            {taxonomyResults.compound.map((result, index) => (
                                <li key={index} onClick={() => handleResultClick(result)}>
                                    {result}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isNotEmpty(taxonomyResults.gene) && (
                    <div className="column">
                        <h3>Gene</h3>
                        <ul className="taxonomy-results">
                            {taxonomyResults.gene.map((result, index) => (
                                <li key={index} onClick={() => handleResultClick(result)}>
                                    {result}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isNotEmpty(taxonomyResults.taxonomy) && (
                    <div className="column">
                        <h3>Taxonomy</h3>
                        <ul className="taxonomy-results">
                            {taxonomyResults.taxonomy.map((result, index) => (
                                <li key={index} onClick={() => handleResultClick(result)}>
                                    {result}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isNotEmpty(taxonomyResults.strains) && (
                    <div className="column">
                        <h3>Strains</h3>
                        <ul className="taxonomy-results">
                            {taxonomyResults.strains.map((result, index) => (
                                <li key={index} onClick={() => handleResultClick(result)}>
                                    {result}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isNotEmpty(taxonomyResults.compound) ||
                isNotEmpty(taxonomyResults.gene) ||
                isNotEmpty(taxonomyResults.taxonomy) ||
                isNotEmpty(taxonomyResults.strains) ? (
                    <div className="close-results-icon" onClick={closeResults}>
                        <Clear />
                    </div>
                ) : null}
            </div>

        </div>
    )
}

export default Search;
